<template>
    <div class="page-content w-100 mobile-view-bottom">
      <!-- start heading -->
      <div class="row">
        <div class="col-sm-12">
          <p class="text-center page-heading">Library</p>
        </div>
      </div>
      <!-- end heading -->
      <div class="row px-2 ">
        <div class=" col-md-12 col-xs-12">
      <section  v-if="tabSelected">
      <div class=" text-center text-align">
      <div class="row banner-text" >
      <div class="col-8 col-lg-10">
      <p class="ResourceActiveTab">{{ activeTab.replace('/','',).replace('Standard Treatment Guidelines','Standard treatment guidelines').replace('icmr-stws','Standard treatment workflows').replace('illustrations','Illustrations').replace('dss-charts','Clinical decision charts') }}</p>
      </div>
      <div class="col-4 col-lg-2">
      <p class="closeButton mt-0" @click="closeResource()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
      </div>
      <div class="hr-border"></div>
      <div class="row mt-3" style="margin: auto;">
      <div class="col-8 col-lg-10" >
      <select class="form-control" style="cursor: pointer;" @change="resource" v-if="tabSelected" v-model="selectedStatus" >
      <option value="1">Select specialty</option>
      <option v-for="resource in doctorPracticeArea" :key="resource">{{resource.practiceArea}}</option>
      </select>
      </div>
      <div class="col-4 col-lg-2 " v-if="tabSelected">
      <div>
      <img src="@/assets/images/settings.png" class="settingsImg" alt="RxIx"   @click="settings()"/>
      </div>
      <div class="d-none d-lg-block">
      <label class="updateSettingText">Update settings</label>
      </div>
      </div>
      </div>
      <div class="row mt-3 mb-5 ResourceOuter"  v-if="source">
      <div class="pagination" >
      <div class="prevBtn" @click="fileImagesPdf(false)" :class="[idx === 0? 'pointerEventNull' : '']"
      v-if="this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'">
      <img style="transform: rotate(180deg); width:100%;" src="@/assets/images/arrowhead.png"  > 
      </div>
      <div class="prevBtn" @click="fileImagesPdf(false)" :class="[idx === 1? 'pointerEventNull' : '']"
      v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'">
      <img style="transform: rotate(180deg);" src="@/assets/images/arrowhead.png"  >  
      </div>
      <div>
      <h2>
      {{ pageCount }}
      </h2>
      </div>
      <div class="nextBtn" @click="fileImagesPdf(true)"  :class="[folders.length -1 == idx ? 'pointerEventNull' : '']">
      <img src="@/assets/images/arrowhead.png"  >  
      </div>
      </div>
      <div class="mt-3 mb-3" style=" color:rgb(239, 63, 63)">
      <h5>Documents appear in alphabetical order.</h5>
      </div>
      <div class="card card-box">
      <div class="card-body libraryImg">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner" :class="[this.activeTab==='dss-charts/' ? 'zoomingImages' :'']">
      <div class="carousel-item active">
      <div class="mt-3 mb-3">
      <div id="javascript:void(0)" >
      <iframe :src="source" class="pdfFiles" 
      v-if="this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'"></iframe>
      <img :src="source" class="imgFiles" 
      v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'" />
      </div>
      </div>
      </div>
      </div>
      <button class="carousel-control-prev" type="button" @click="fileImagesPdf(false)" 
      v-if="this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'"
      data-bs-target="#carouselExampleControls" data-bs-slide="prev"
      :class="[idx === 0? 'pointerEventNull' : '']">
      <span class="carousel-control-prev-icon" :class="[idx===0? 'disableBtn': 'pointerAuto']"></span>
      </button>
      <button class="carousel-control-prev" type="button" @click="fileImagesPdf(false)"
      v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'"
      data-bs-target="#carouselExampleControls" data-bs-slide="prev"
      :class="[idx === 1? 'pointerEventNull' : '']">
      <span class="carousel-control-prev-icon" :class="[idx===1? 'disableBtn': 'pointerAuto']"></span>
      </button>
  
      <button class="carousel-control-next" type="button" @click="fileImagesPdf(true)"
      data-bs-target="#carouselExampleControls" data-bs-slide="next"
      :class="[folders.length -1 == idx ? 'pointerEventNull' : '']">
      <span class="carousel-control-next-icon" :class="[folders.length -1 == idx ? 'disableBtn' : 'pointerAuto']"></span>
      </button>
      </div>
      </div>
      </div>
      </div>
      <div  style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)" v-if="selectedStatus !== '1' && !source">
      <h3>No reource found</h3>
      </div>
      </div>
      </section>
      <div v-if="hideTopicResource">
      <p class="resourceTypeText">Select resource type</p>
      <div class="hr-border mb-3"></div>
      <div class="ResourceName" v-for="(tab, index) in mainFolder" :key="index">
      <a href="javascript:void(0)" @click="onSelectTabs(index, tab)" >{{ tab.replace('/','',).replace('Standard Treatment Guidelines','Standard treatment guidelines').replace('dss-charts','Clinical decision charts').replace('illustrations','Illustrations').replace('icmr-stws','Standard treatment workflows')}}</a>
      </div>
      <div class="ResourceName cursor">
      <a class="resColor"  @click="disorderData(index, tab)">Manual of disorders</a>
      </div>
      </div>
      <div v-if="open">
      <div class=" text-center text-align">
      <div class="" v-if="data">
      <div class="row banner-text">
      <div class="col-8 col-lg-10">
      <p class="ResourceActiveTab">Manual of disorders</p>
      </div>
      <div class="col-4 col-lg-2">
      <p class="closeButton mt-0" @click="closeResource()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
      </div>
      <div class="hr-border"></div>
      <div class="row mt-3" style="margin: auto;">
      <div class="col-12 col-lg-10">
      <select class="form-control" style="cursor: pointer;" @change="openResource($event)" v-model="openDisorder">
      <option value="1">Select disorder</option>
      <option :value="value" v-for="value in disorderArray" :key="value" >
      {{ value.disorder }}
      </option>
      </select>
      </div>
      </div>
      </div>
      <div class="row mt-3" style="margin: auto;" v-if="openRes">
      <div class="card border-box dropdown">
      <div class="flex">
      <h2 class="col-11 manual-title"  v-for="index in ValuesData" :key="index">{{ index }}</h2>
      <p class=" mt-0 closeButton" @click=" closeResTopic()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
      <hr class="mobilemargin" />
      <p  v-for="index in dataValue" :key="index" style="margin-top: 5px; cursor: pointer; margin-left: 10px;"
      @click="openTopic(index.topic)">{{ index.topic }}</p>
      </div>
      </div>
      <div class="row" style="margin: auto;">
      <div class="card border-box resource" v-if="topicRes" style="margin-top: 10px;">
      <div class="flex">
      <h2 class="col-11 manual-title"  >{{ topicData }}</h2>
      <p class="mt-0 closeButton" @click="closeRes()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
      <hr class="mobilemargin" />
      <p  v-for="index in ArrayData" :key="index" style="margin-top: 5px; cursor: pointer; margin-left: 10px;"
      @click="openSignup(index)">{{ index.resource_name }}</p>
      </div>
      </div>
      <!-- </div> -->
      </div>
      </div>
      </div>
      </div>
      </div>
  <clinicianotherfooter></clinicianotherfooter>
  </template>
  <script>
  import clinicianotherfooter from "./clinician-others-footer.vue"
  import axios from 'axios'
  export default {
    components: {
        clinicianotherfooter
    },
    data() {
      return {
        clinicResource: true,
              currentPath: "",
              issubmit: false,
              mainFolder: [],
              subFolder: [],
              folders: [],
              activeTab: null,
              practiceId:'',
              prefixData: '',
              source: '',
              fileType: '',
              idx: 0,
              tabSelected: false,
              showResource: false,
              doctorPracticeArea:[],
              doctorArea:[],
              hideTopicResource:true,
              open:false,
              disorderArray:[],
              disorderValue:[],
              dataValue:[],
              openRes:false,
              topicRes:false,
              data:false,
              topicValue:[],
              openDisorder:null,
              selectedStatus:null,
              ArrayData:[]
      }
    },
    computed: {
        pageCount() {
  if (this.folders.length === 0) {
    return "0 of 0";
  }
  
  let currentPage;
  let totalPages;
  
  // Adjust the page count based on the starting index
  if (this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/') {
    currentPage = this.idx + 1 - 1; // Subtract 1 for starting from 1
    totalPages = this.folders.length - 1; // Subtract 1 for starting from 2
  } else {
    currentPage = this.idx + 1;
    totalPages = this.folders.length;
  }
  
  return `${currentPage} of ${totalPages}`;
  }
  
  },
    created: function () {
  this.getMainFolder();
  this.getPracticArea().then(practiceAreaData => {
              this.getDoctor(practiceAreaData);
          })
        //   this.topicResource();
          this.disorderDataValue()
  },
  mounted(){
    this.openDisorder = "1"
  },
    methods: {
        closeResource(){
            this.disorderDataValue();
            // location.reload();
            this.hideTopicResource=true
            this.tabSelected=false
            this.open = false
            this.openRes = false;
            this.topicRes = false;
        },
      async settings() {
        const query = this.$route.query;
        window.scrollTo(0, 0);
        this.$router.push({ path: `/doctor/settings`, query });  
      },
      async getMainFolder() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
              await axios
                  .get(`${BASE_API_URL}/library/point`)
                  .then((response) => {
                      this.mainFolder = response.data.data.allKeys;
                    const icmr=this.mainFolder[0];
                    const stg=this.mainFolder[1];
                    const illustrations=this.mainFolder[2];
                    const dss = this.mainFolder[3];
                    this.mainFolder[3]=stg
                    this.mainFolder[2]=dss
                    this.mainFolder[1]=icmr
                    this.mainFolder[0]=illustrations
                    this.mainFolder.splice(this.mainFolder.indexOf(4))
                    this.mainFolder.splice(this.mainFolder.indexOf(3))
                  })
          },
          subFolderLists(type) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
              axios
                  .get(`${BASE_API_URL}/library/dss?mainFolder=${type}`)
                  .then((response) => {
                      this.subFolder = response.data.data.allKeys  
                  })
          },
          onSelectTabs(index, folderName) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.hideTopicResource=false
              this.tabSelected = true
              this.activeTab = index;
              this.activeTab = folderName;
              if(this.activeTab){
                this.selectedStatus='1'; 
                    }
                if(this.activeTab){
                    this.source=false
                    }
              this.subFolderLists(folderName);
              if (this.prefixData) {
                  const subFiles = `${this.activeTab}${this.prefixData}`
                  axios
                      .get(`${BASE_API_URL}/library/chart?prefix=${subFiles}`)
                      .then((response) => {
                          this.folders = response.data;
                        if(this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'){
                              this.idx=0
                              this.source = this.folders[0]
                          }else if(this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'){
                              this.idx=1
                              this.source = this.folders[1]
                          }
                          if( this.selectedStatus){
                            this.source= false
                        }
                          this.fileType = this.source.split('.')[1];
                          [...this.doctorArea]                       
                      })
                      .catch(function (error) {
                          console.log(error, 'error');
                      })
              }
          },
          resource(event) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
              this.prefixData = event.target.value;
              const subFiles = `${this.activeTab}${event.target.value}`
              axios
                  .get(`${BASE_API_URL}/library/chart?prefix=${subFiles}`)
                  .then((response) => {
                      this.folders = response.data;
                      this.folders = this.folders.map(url => {
                        if (url.includes('#')) {
                            return url + '&toolbar=0&navpanes=0';
                        } else {
                            return url + '#toolbar=0&navpanes=0';
                        }
                    });
                      if(this.folders){
                          this.idx=0;
                      }
                       if(this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'){
                              this.idx=0
                              this.source = this.folders[0]
                          }else if(this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'){
                              this.idx=1
                              this.source = this.folders[1]
                          }
                      this.fileType = this.source.split('.')[1];
                  })
                  .catch(function (error) {
                      console.log(error, 'error');
                  })
          },
          fileImagesPdf(isSelect) {
              if (this.activeTab && this.prefixData) {
                  if (isSelect === true) {
                      this.idx++
                  } else if (isSelect === false) {
                      this.idx--  
                  }
                  this.source = this.folders[this.idx]
                  this.fileType = this.source.split('.')[1];
              }
          },
          getDoctor(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
            .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
            .then((response) => {
              if(response.data){
                  this.doctorPracticeArea=response.data.practiceId
              }
            })
          },
          async getPracticArea() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
              return axios
                  .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`).then(response => {
                      return response.data;
                  })
          },
          disorderData(){
            this.openDisorder = "1"
            this.hideTopicResource = false;
            this.open = true;
            this.data = true;
            this.openRes = false;
          },
          disorderDataValue(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
            .get(`${BASE_API_URL}/msd-disorders`)
            .then((response)=>{
                this.disorderArray=[];
                this.disorderArray = response.data;
                response.data.map((data)=>{
                    if(this.disorderValue === 'select')
                    this.disorderValue = data.disorder
                })
            })
          },
          openResource(e) {
            this.selectOption = e.target.value;
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            if(this.selectOption == 1){
                this.openRes = false;
            }
            else{
                this.openRes = true;
            }
            axios
                .get(`${BASE_API_URL}/msd-disorderTopic?q=${this.openDisorder?.disorder}`)
                .then((response) => {
                    this.dataValue = response.data
                    this.dataValue.map((datas)=>{
                        this.arr = datas.disorder
                        this.ValuesData = [response.data[0].disorder]
                    })
                })
        },
        openTopic(index){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
            .get(`${BASE_API_URL}/msd-topicResource?q=${index}`)
            .then((response) => {
                this.ArrayData = response.data
                this.openRes = false;
                this.topicRes = true;
                this.ArrayData.map((datas)=>{
                        this.topicData = datas.topic
                        this.urlTopic = datas.resource_url
                    })
                })
        },
        closeResTopic() {
            this.openRes = false;
            this.topicRes = false;
        },
        closeRes() {
            this.openRes = true;
            this.topicRes = false;
        },
        openSignup(index) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/msd-topicResources`)
                .then((response) => {
                    response.data.forEach((data) => {
                        if (index.id === data.id) {
                            this.dataUrl = data.resource_url;
                            window.open(this.dataUrl)
                        }
                    })
                })
                .catch((error) => {
                    console.error('Error fetching resource URL:', error);
                });
        },
    },
  }
  </script>
  <style>
     h2.manual-title{
        font-size: 22px;
        height: auto;
    }
  .pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
      .manual-title{
        /* margin-top: 10px; */
        height: auto;
    }
      hr.mobilemargin{
    margin-top: 0px;
    }
  .cursor{
    cursor: pointer;
  }
  .card.border-box.dropdown {
    width: 100%;
    margin: auto;
    border: 1px solid #000;
    text-align: start;
  }
  .card.border-box.resource {
    width: 100%;
    margin: auto;
    border: 1px solid #000;
    text-align: start;
  }
  .ResourceOuter{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .card-body.libraryImg{
    padding: 0px;
    height: auto !important;
  }
  .card.card-box {
    /* width: auto; */
    margin: auto;
    border: 1px solid #000;
  }
  .ResourceName a{
    font-size: 19px;
    /* color: #79665a; */
    padding: 0.5rem 1rem
  }
  /* .resColor{
    color: #79665a;
  } */
  .resourceTypeText{
    font-size: 25px;
    padding: 0.5rem 1rem;
    margin-bottom: 0rem;
  }
  .ResourceActiveTab{
    font-size: 25px;
    text-align: left;
  }
  .carousel-item {
    position: relative;
    display: none;
    /* float: left; */
    width: 100%;
    /* margin-right: -100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
  }
  .zoomingImages :hover{
    width: 950px;
    display: block;
    margin-left:auto;
    margin-right:auto;
  }
  
  .settingsImg{
      width: 40px;
      height: 40px;
      cursor: pointer;
      /* margin-left: 602px; */
  }
  .dropDownSettings{
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
  }
  select.form-control {
    width: 400px;
      font-size: 17px;
      text-align: left;
      appearance: auto;
     /* margin-left:auto; */
  }
  
  .carousel-control-prev-icon 
  {
    background-color: #5f9ea0;
      display: inline-block;
      width: 6rem;
      height: 6rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
  }
  .prevBtn{
    cursor: pointer;
    width: 6rem;
    height: 6rem;
  }
  .nextBtn{
    cursor: pointer;
    width: 6rem;
    height: 6rem;
  }
  .carousel-control-next, .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-next-icon {
    background-color: #5f9ea0;
      display: inline-block;
      width: 6rem;
      height: 6rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
  }
  .pointerEventNull {
      pointer-events: none;
      opacity: 0.2;
  }
  .imgFiles {
    width: 100%;
    height:100rem;
  }
  .pdfFiles {
      width: 100%;
      height: 100rem;
  }
  
  @media screen and (max-width:991px) {
    .banner-text{
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
    }
    .mobile-view-bottom{
        margin-bottom: 7rem;
    }
    h2.manual-title{
        font-size: 20px;
        height: auto;
    }
    .card.border-box.dropdown {
        width: 100%;
    }
    .card.border-box.resource {
        width: 100%;
    }
    select.form-control {
    width: 300px;
    }
    .card.card-box {
        width: 100%;
    }
    .pdfFiles {
    width:100%;
    height:900px;
  }
  .imgFiles {
    width:100%;
    height:900px;
  }
  .carousel-control-next-icon {
    width: 5rem;
    height:5rem;
  }
  .carousel-control-prev-icon {
    width: 5rem;
    height:5rem;
  }
  .prevBtn{
    width: 5rem;
    height: 5rem;
  }
  .nextBtn{
    width: 5rem;
    height: 5rem;
  }
  }
  @media screen and (max-width:768px) {
    .pdfFiles {
    width:100%;
    height:650px;
  }
  .imgFiles {
    width:100%;
    height:650px;
  }
  .carousel-control-next-icon {
    width: 4rem;
    height:4rem;
  }
  .carousel-control-prev-icon {
    width: 4rem;
    height:4rem;
  }
  .prevBtn{
    width: 4rem;
    height: 4rem;
  }
  .nextBtn{
    width: 4rem;
    height: 4rem;
  }
  }
  @media screen and (max-width:576px) {
    h2.manual-title{
        font-size: 18px;
    }
    .ResourceActiveTab{
        font-size: 20px;
    }
    .resourceTypeText{
        font-size: 20px;
    }
    .ResourceName a{
        font-size: 16px;
    }
    select.form-control {
    width: 200px;
    }
    .pdfFiles {
    width:100%;
    height:500px;
  }
  .imgFiles {
    width:100%;
    height:500px;
  }
  }
  @media screen and (max-width:450px) {
    .pdfFiles {
    width:100%;
    height:400px;
  }
  .imgFiles {
    width:100%;
    height:400px;
  }
  .carousel-control-next-icon {
    width: 3rem;
    height:3rem;
  }
  .carousel-control-prev-icon {
    width: 3rem;
    height:3rem;
  }
  .prevBtn{
    width: 3rem;
    height: 3rem;
  }
  .nextBtn{
    width: 3rem;
    height: 3rem;
  }
  }
  
  @media screen and (max-width:435px) {
    .updateSettingText{
  font-size: 11px;
  }
  .settingsImg {
    width:30px;
    height: 30px;
  }
  }
  @media screen and (max-width:390px) {
    .pdfFiles {
    width:100%;
    height:350px;
  }
  .imgFiles {
    width:100%;
    height:350px;
  }
  }
  </style>
  
  